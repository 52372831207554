.company-page-container {
    display: flex;
    width: 80%;
    margin: 0 auto;
    min-height: 80vh;
    overflow: hidden;
    max-width: 1600px;
  }


    /* Responsywność: kolumny układają się pionowo na mniejszych ekranach */
@media (max-width: 768px) {

    .company-page-container {
        width: 95%;
      }

    }

  .company-page-container h1 {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .company-page-container h2 {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .company-page-container p {

    line-height: 1.6;
    text-align: left;
    padding-bottom: 1.5vh;

  }


  .box-column {
    width: 90%;
    margin: 0 auto;

  }