/* components/About.css */
.feature-container {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-around; /* Równomierne rozmieszczenie kolumn */
    flex-wrap: wrap; /* Zapewnia responsywność */
    padding: 20px;
  }

  .feature {
    flex: 1; /* Każda kolumna zajmuje równą przestrzeń */
    margin: 10px; /* Margines dla odstępu między kolumnami */
    text-align: left; /* Centrowanie tekstu wewnątrz kolumny */
  }

  .feature h2 {
    font-size: 1.5rem;
    font-weight: 200;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  .separator {
    clear: both;
  }

  .fe-text {
    background-color: #1A1B2F; /* Kolor tła kafelka */
    border-radius: 10px; /* Zaokrąglenie rogów */
    padding: 20px; /* Wewnętrzny odstęp */
    color: white; /* Kolor tekstu */
    font-size: 1.3rem;
    line-height: 3.6vh;
    min-height: 30vh;
  }



  @media (max-width: 768px) {
    .feature {
      flex-basis: 100%; /* Każda kolumna zajmuje pełną szerokość na małych ekranach */
    }

    .feature-container {
      display: flex;
      width: 95%;
      margin: 0 auto;
      justify-content: space-around; /* Równomierne rozmieszczenie kolumn */
      flex-wrap: wrap; /* Zapewnia responsywność */
      padding: 20px;
    }
  }