.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
  margin: auto;
}

.logo-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Wyrównanie logo do lewej */
}

.logo {
  max-width: 100%;
  height: auto;
}


/* Dodaj media query dla mniejszych ekranów */
@media (max-width: 768px) {
  .logo-container {
    justify-content: flex-start; /* Upewnij się, że logo jest zawsze do lewej */
  }

  .logo {
    max-width: 70%;
    height: auto;
  }

}

.hamburger img {
  width: 48px; /* Dostosuj szerokość */
  height: 48px; /* Zachowaj proporcje */
  background-color: transparent;
}


.hamburger {
  display: block; /* Zawsze wyświetlaj przycisk hamburgera */
  cursor: pointer;
  z-index: 101;
  /* Dodatkowe style dla ikony hamburgera */
}

.menu-container {
  position: fixed;
  z-index: 100;
  top: 0;
  right: -100%; /* Początkowo ukryte poza ekranem */
  width: 300px; /* lub dowolna szerokość */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.781); /* Tło menu */
  transition: right 0.3s; /* Animacja */
  /* Dodatkowe style */
}

.menu-container.open {
  right: 0; /* Pokaż menu */
}

.menu {
  padding-top:15vh; /* Odstęp od góry */
}

/* Stylowanie kafelków dla linków */
.menu a {
  display: block; /* Ustawienie linków jako bloków */
  background-color: #f0f0f0; /* Kolor tła kafelka */
  color: #333; /* Kolor tekstu */
  padding: 15px; /* Wewnętrzny odstęp dookoła tekstu */
  margin: 10px; /* Margines dookoła kafelka */
  text-align: center; /* Wyrównanie tekstu do środka */
  text-decoration: none; /* Usunięcie podkreślenia tekstu */
  border-radius: 5px; /* Zaokrąglenie rogów */
  transition: background-color 0.3s; /* Animacja zmiany tła */
}

/* Efekt hover dla kafelków */
.menu a:hover {
  background-color: #e0e0e0; /* Zmiana koloru tła po najechaniu myszką */
  /* Możesz dodać inne efekty jak cień lub zmianę koloru tekstu */
}