.banner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    margin-top: 300px;
  }

  .banner-img {
    max-width: 55%;
    height: auto;
    display: block;
    margin: auto;
  }

  .slider {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .slide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  @media (max-width: 768px) {
    .banner-img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: auto;
    }
  }

  @media (min-width: 2000px) {
    .banner-img {
      max-width: 40%; /* Zmniejszony rozmiar dla bardzo dużych ekranów */
      height: auto;
      display: block;
      margin: auto;
    }
  }
