.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;

  }

  @media (max-width: 768px) { /* Ajust the breakpoint as needed */
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        width: 100%;
        text-align: center;
        padding:5px;
    }
}
  .footer-column {
    flex: 1;
    text-align: center;
    padding:10px;
  }

  /* Stylowanie przycisków zmiany języka */
  .footer-column div button {
    margin: 0 5px;
    padding: 5px 10px;
  }

  .footer-column h2 {
    text-align: left;
    font-weight: 200;
  }

  .footer-text {
    background-color: #1A1B2F; /* Kolor tła kafelka */
    border-radius: 10px; /* Zaokrąglenie rogów */
    padding: 20px; /* Wewnętrzny odstęp */
    margin: 20px 0; /* Margines zewnętrzny */
    color: white; /* Kolor tekstu */
    text-align: left;
    min-height: 23vh;
  }

  .footer-text p {
    margin: 0; /* Usunięcie domyślnego marginesu paragrafu */
    font-size: 1.1rem;
    line-height: 3.0vh;
    padding-top: 10px;
  }

  .footer-column ul {
    list-style: none; /* Usuwa domyślne punkty listy */
    padding: 0; /* Usuwa domyślny padding */
    text-align: left; /* Wyrównanie tekstu do lewej */
}

.footer-column ul li {
    padding: 5px 0; /* Odstępy między elementami listy */
    border-bottom: #242f3b 1px solid;
}

.footer-column ul li a {
    color: white; /* Kolor tekstu linków */
    text-decoration: none; /* Usuwa podkreślenie linków */
    transition: color 0.3s; /* Animacja zmiany koloru */
    font-size: 1.1rem;
    line-height: 3.0vh;
}

.footer-column ul li a:hover {
    color: #e0e0e0; /* Kolor tekstu linków przy najechaniu myszką */
}

.lang-box {
  padding:10px;
}

.google {
  display: flex;
  justify-content: center; /* Wycentrowanie w poziomie */
  align-items: center; /* Wycentrowanie w pionie */
  padding: 10px;
}

.google-play-logo {
  width: 20vh;
  height: auto;
}

