* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }



.hero-section {
    width: 80%;
    margin: 0 auto;
    min-height: 80vh;
    overflow: hidden;
    max-width: 1600px;
  }

  .hero-description h1 {
    font-size: 2rem;
    padding-bottom: 2vh;
  }

  .hero-description h2 {
    font-size: 2rem;
    font-weight: 200;
    padding-bottom: 2vh;
  }

  .hero-description {
    text-align: left;
    padding: 20px;
  }

  .hero-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .hero-column {
    flex: 1; /* Każda kolumna zajmuje połowę szerokości kontenera */
    padding: 15px;
  }


  .hero-text {
    background-color: #1A1B2F; /* Kolor tła kafelka */
    border-radius: 10px; /* Zaokrąglenie rogów */
    padding: 20px; /* Wewnętrzny odstęp */
    margin: 20px 0; /* Margines zewnętrzny */
    color: white; /* Kolor tekstu */
  }

  .hero-text p {
    margin: 0;
    font-size: 1.3rem;
    line-height: 3.6vh;
  }

  .hero-bg {
    width: 100%;
    border-radius: 10px 0 0 10px; /* Zaokrąglenie tylko lewego górnego i dolnego rogu */
    background: linear-gradient(to right, #151627 60%, transparent 100%); /* Gradient od koloru do przezroczystości */
    background-repeat: no-repeat; /* Zapobiega powtarzaniu się tła */
    background-size: 100% 100%;
    padding: 20px; /* Wewnętrzny odstęp */
    margin: 20px 0; /* Margines zewnętrzny */
  }

    /* Stylowanie obrazków */
    .home-image {
      width: 70%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: 3vh;

    }

  .hero-bg1 {
    width: 100%;
    border-radius: 0px 10px 10px 0px; /* Zaokrąglenie tylko lewego górnego i dolnego rogu */
    background: linear-gradient(to left, #151627 60%, transparent 100%); /* Gradient od koloru do przezroczystości */
    background-repeat: no-repeat; /* Zapobiega powtarzaniu się tła */
    background-size: 100% 100%;
    padding: 20px; /* Wewnętrzny odstęp */
    margin: 20px 0; /* Margines zewnętrzny */
  }

  .details-image {
    width: 70%;
    height: auto;
    display: block;
    margin-left: 3vh;
    margin-right: auto;

  }

  /* Responsywność: kolumny układają się pionowo na mniejszych ekranach */
@media (max-width: 768px) {
  .hero-row {
    flex-direction: column;
  }

  .hero-section {
    width: 95%;
  }

  .home-image, .details-image {
    width: 95%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto; /* Centrowanie obrazu */
  }
}
